import SportsBarIcon from "@mui/icons-material/SportsBar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BlindsIcon from "@mui/icons-material/Blinds";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WcIcon from "@mui/icons-material/Wc";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import WalletIcon from "@mui/icons-material/Wallet";
import BugReportIcon from "@mui/icons-material/BugReport";

const collection = [
  {
    q: "What is a programmer's favourite hangout place?",
    a: "Foo Bar",
    icon: <SportsBarIcon />,
  },
  {
    q: "Why did the programmer quit his job?",
    a: "Because he didn't get arrays.",
    icon: <AttachMoneyIcon />,
  },
  {
    q: "What accommodations did the JavaScript developer request at the hotel?",
    a: "A room with a Vue.",
    icon: <BlindsIcon />,
  },
  {
    q: "Why do Java programmers have to wear glasses?",
    a: "Because they don't C#",
    icon: <VisibilityOffIcon />,
  },
  {
    q: "Why don’t bachelors like Git?",
    a: "Because they are afraid to commit.",
    icon: <WcIcon />,
  },
  {
    q: "Why do programmers prefer dark mode?",
    a: "Because the light attracts bugs.",
    icon: <DarkModeIcon />,
  },
  {
    q: "How many programmers does it take to change a light bulb?",
    a: "None. It's a hardware issue.",
    icon: <LightbulbIcon />,
  },
  {
    q: "Why did the developer go broke?",
    a: "Because he used up all his cache.",
    icon: <WalletIcon />,
  },
  {
    q: "How do you comfort a JavaScript bug?",
    a: "You console it.",
    icon: <BugReportIcon />,
  },
];

export default collection;
