import React from "react";
import "./Home.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import collection from "./jokecollection.js";

const Jokes = () => {
  return (
    <div>
      <aside>
        <h1>
          <b>
            Or maybe read some
            <br />
            nerdy jokes?
          </b>
        </h1>
        <ArrowForwardIosIcon fontSize="large" className="arrow" />
      </aside>
      {collection.map((joke, i) => {
        return (
          <article key={"key_" + i}>
            <p>
              Q: <i>{joke.q}</i>
              <br /> A: <i>{joke.a}</i>
            </p>
            {joke.icon}
          </article>
        );
      })}
    </div>
  );
};

export default Jokes;
