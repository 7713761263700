import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "./Hangman.scss";
import StarIcon from "@mui/icons-material/Star";
import { words } from "./hangmanwords";
import InstallationPrompt from "../InstallationPrompt";

const Hangman = () => {
  const [wordList, setWordList] = useState(["-"]);
  const [gameStarted, setGameStarted] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");
  const [displayWord, setDisplayWord] = useState([]);
  const [guessedLetters, setGuessedLetters] = useState([]);
  const [remainingAttempts, setRemainingAttempts] = useState(13);
  const [timer, setTimer] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (wordList.length > 1) setGameStarted(true);
    // eslint-disable-next-line
  }, [wordList]);

  useEffect(() => {
    if (gameStarted) selectRandomWord();
    // eslint-disable-next-line
  }, [gameStarted]);

  useEffect(() => {
    updateDisplayWord();
    if (guessedLetters.length === 1) startTimer();
    // eslint-disable-next-line
  }, [selectedWord, guessedLetters]);

  useEffect(() => {
    if (timeRemaining === 0 || remainingAttempts === 0) {
      handleLoss();
    }
    if (displayWord.join("") === selectedWord) {
      handleWin();
    }
    // eslint-disable-next-line
  }, [timeRemaining, remainingAttempts]);

  const selectRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * wordList.length);
    const randomWord = wordList[randomIndex].toLowerCase();
    setSelectedWord(randomWord);
    setDisplayWord(new Array(randomWord.length).fill("_"));
    resetTimer();
  };

  const handleGuess = (letter) => {
    if (!guessedLetters.includes(letter)) {
      setGuessedLetters([...guessedLetters, letter]);
      if (!selectedWord.includes(letter)) {
        setRemainingAttempts(remainingAttempts - 1);
      }
    }
  };

  const updateDisplayWord = () => {
    const newDisplayWord = selectedWord
      .split("")
      .map((letter) => (guessedLetters.includes(letter) ? letter : "_"));
    setDisplayWord(newDisplayWord);
  };

  const isGameWon = () => displayWord.join("") === selectedWord;

  const isGameLost = () => remainingAttempts === 0 || timeRemaining === 0;

  const handleLoss = () => {
    clearInterval(timer);
    setRemainingAttempts(0);
  };
  const handleWin = () => {
    clearInterval(timer);
  };

  const handleRestart = () => {
    setWordList(["-"]);
    setGameStarted(false);
    setDisplayWord([]);
    setSelectedWord("");
    setGuessedLetters([]);
    setRemainingAttempts(13);
    setTimeRemaining(60);
    setShowPrompt(false);
  };

  const startTimer = () => {
    setTimer(
      setInterval(() => {
        setTimeRemaining((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000)
    );
  };

  const resetTimer = () => {
    clearInterval(timer);
    setTimeRemaining(60);
  };

  return (
    <>
      {showPrompt && <InstallationPrompt />}
      <div className="hangman">
        <div className="header">
          <div className="info">
            <p className={remainingAttempts < 3 ? "circle danger" : "circle"}>
              {remainingAttempts}
            </p>
            <span className="small-text">Attempts left</span>
          </div>
          <p className="display-word">{displayWord.join(" ").toUpperCase()}</p>
          <div className="info" onClick={resetTimer}>
            <p className={timeRemaining < 10 ? "circle danger" : "circle"}>
              {timeRemaining}
            </p>
            <span className="small-text">Time left</span>
          </div>
        </div>
        <div className="container">
          <div className="result">
            {isGameWon() && gameStarted && (
              <h1>
                Congratulations!
                <br />
                You won!
              </h1>
            )}
            {isGameLost() && !isGameWon() && (
              <h1>
                Sorry, you lost.
                <br />
                The correct word was: <b>{selectedWord.toUpperCase()}</b>
              </h1>
            )}
          </div>
          {!gameStarted && (
            <div className="level">
              {/* <p>Select level:</p> */}
              {/* <Button
                className="restart-button"
                variant="outlined"
                onClick={() => setWordList(easyWords)}
              >
                easy
              </Button> */}
              <Button
                className="restart-button"
                variant="outlined"
                onClick={() => setWordList(words)}
              >
                start
              </Button>
              {/* <Button
                className="restart-button"
                variant="outlined"
                onClick={() => setWordList(hardWords)}
              >
                hard
              </Button> */}
            </div>
          )}
          {!isGameWon() && !isGameLost() && gameStarted && (
            <div className="all-letters">
              {Array.from({ length: 26 }, (_, i) =>
                String.fromCharCode("A".charCodeAt(0) + i)
              ).map((letter) => (
                <button
                  key={letter}
                  onClick={() => handleGuess(letter.toLowerCase())}
                  className={
                    guessedLetters.includes(letter.toLowerCase())
                      ? "disabled"
                      : undefined
                  }
                >
                  {letter}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="hangman-container">
          <div className="middle">
            {remainingAttempts < 12 && (
              <div className="left">
                {remainingAttempts < 11 && <div className="top" />}
                {remainingAttempts < 10 && <div className="angle" />}
                {remainingAttempts < 9 && <div className="rope" />}
              </div>
            )}
            <div className="body">
              {remainingAttempts < 8 && (
                <div className="head">
                  <StarIcon style={{ width: "15px" }} />
                  <StarIcon style={{ width: "10px" }} />
                </div>
              )}
              {remainingAttempts < 7 && (
                <div className="spine">
                  {remainingAttempts < 6 && <div className="right-arm" />}
                  {remainingAttempts < 5 && <div className="left-arm" />}
                  {remainingAttempts < 4 && (
                    <div className="left-leg">
                      {remainingAttempts < 3 && <div className="left-foot" />}
                    </div>
                  )}
                  {remainingAttempts < 2 && (
                    <div className="right-leg">
                      {remainingAttempts < 1 && <div className="right foot" />}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {remainingAttempts < 13 && <div className="bottom" />}
        </div>
        <div className="center">
          <Button
            className="restart-button"
            variant="outlined"
            onClick={handleRestart}
            style={{ visibility: gameStarted ? "visible" : "hidden" }}
          >
            restart game
          </Button>
        </div>
      </div>
    </>
  );
};

export default Hangman;
