import "./Memory.scss";

const Card = ({ i, number, show, hide, showCard }) => {
  return (
    <section className="card-container">
      <div
        className={`card ${show ? `card-flip` : ``}`}
        onClick={() => showCard(i)}
      ></div>
      <div className={`card ${!show ? `card-flip` : ``}`}>
        {" "}
        {!hide}
        <img src={require(`../assets/${number}.png`)} alt=" " />
      </div>
    </section>
  );
};

export default Card;
