import React from "react";
import { useEffect, useState, useRef } from "react";
import "./Memory.scss";
import Card from "./Card";
import { Button } from "@mui/material";
import InstallationPrompt from "../InstallationPrompt";

const Memory = () => {
  const init = [
    { number: 1, show: false },
    { number: 1, show: false },
    { number: 2, show: false },
    { number: 2, show: false },
    { number: 3, show: false },
    { number: 3, show: false },
    { number: 4, show: false },
    { number: 4, show: false },
    { number: 5, show: false },
    { number: 5, show: false },
    { number: 6, show: false },
    { number: 6, show: false },
  ];
  const interval = useRef();
  const [showPrompt, setShowPrompt] = useState(false);
  const [cards, setCards] = useState(() => shuffleArray(init));
  const [last, setLast] = useState(null);
  const [win, setWin] = useState(false);
  const [hideNumbers, setHideNumbers] = useState(false);
  const [progress, setProgress] = useState({ clicks: 0, time: 0 });
  const [level, setLevel] = useState(0);
  const HIDE_DELAY = 1000;
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function handleShowCard(i) {
    setProgress((p) => {
      let { clicks } = p;
      clicks++;
      return { ...p, clicks };
    });
    const update = [...cards];
    update[i].show = true;
    if (last === null) {
      setLast(i);
    } else if (cards[last].number === cards[i].number) {
      setLast(null);
    } else {
      setLast(null);
      hideCards(last, i);
    }
    setCards(update);
  }

  function hideCards(last, i) {
    setTimeout(() => {
      const update = [...cards];
      update[last].show = false;
      update[i].show = false;
      setCards(update);
    }, HIDE_DELAY);
  }
  function startGame(newGame) {
    setHideNumbers(true);
    const setNewGame = [...shuffleArray(newGame)];
    setCards(setNewGame);
    setWin(false);
    setProgress({ clicks: 0, time: 0 });
    clearInterval(interval.current);
    setTimeout(() => {
      setHideNumbers(false);
    }, HIDE_DELAY);
  }
  function displayTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return (
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  }
  function changeLevel(e) {
    const newGame = [...init];
    for (let i = 1; i <= e; i++) {
      newGame.push({ number: init.length / 2 + i, show: false });
      newGame.push({ number: init.length / 2 + i, show: false });
    }
    setLevel(e);
    startGame(newGame);
  }
  useEffect(() => {
    const allShow = cards.every((card) => card.show === true);
    if (allShow) {
      setWin(true);
      setShowPrompt(false);
    }
  }, [cards]);

  useEffect(() => {
    if (progress.clicks === 1) {
      interval.current = setInterval(() => {
        setProgress((p) => {
          let { time } = p;
          time++;
          return { ...p, time };
        });
      }, HIDE_DELAY);
    }
    if (win) {
      clearInterval(interval.current);
    }
  }, [progress.clicks, win]);

  return (
    <>
      {showPrompt && <InstallationPrompt />}
      <div className="memory">
        {win && <h1>You made it!!</h1>}
        <section
          className="container"
          style={{ marginTop: win ? "1rem" : "2rem" }}
        >
          {cards.map((card, index) => {
            return (
              <Card
                {...card}
                i={index}
                hide={hideNumbers}
                key={index}
                showCard={handleShowCard}
              />
            );
          })}
        </section>
        <section
          className="score-container"
          style={{ marginTop: level === 4 && "1rem" }}
        >
          <p>Number of clicks: {progress.clicks}</p>
          <p>Timer: {displayTime(progress.time)}</p>
          <br />
          <p>Number of cards:</p>
          <section className="level-container">
            <Button
              variant="outlined"
              onClick={() => changeLevel(0)}
              className={level === 0 ? "act" : ""}
            >
              12
            </Button>
            <Button
              variant="outlined"
              onClick={() => changeLevel(2)}
              className={level === 2 ? "act" : ""}
            >
              16
            </Button>
            <Button
              variant="outlined"
              onClick={() => changeLevel(4)}
              className={level === 4 ? "act" : ""}
            >
              20
            </Button>
            <div className="reset">
              {progress.clicks !== 0 && (
                <Button
                  variant="outlined"
                  onClick={() => changeLevel(level)}
                  className={"reset-button"}
                >
                  RESTART
                </Button>
              )}
            </div>
          </section>
        </section>
      </div>
    </>
  );
};

export default Memory;
