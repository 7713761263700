import React, { useState } from "react";
import "./App.scss";
import Games from "./components/games/Games";
import Home from "./components/start/Home";
import MainMenu from "./components/navigation/MainMenu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import MailIcon from "@mui/icons-material/Mail";
import WestIcon from "@mui/icons-material/West";

const App = () => {
  const [active, setActive] = useState("home");

  const handleClick = () => {
    const arrow = document.getElementById("hint-arrow");
    if (arrow) {
      arrow.classList.remove("social-hint");
    }
  };

  return (
    <main className="App">
      <MainMenu active={active} setActive={setActive} />
      <section className="main-wrapper">
        {active === "home" && <Home setActive={setActive} />}
        {active === "games" && <Games back={() => setActive("home")} />}

        <footer id="main-footer" className="main-footer">
          <a
            href="https://www.linkedin.com/in/linda-jansson/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon fontSize="medium" />
          </a>
          <a
            href="https://github.com/linda-jansson-gbg"
            target="_blank"
            rel="noreferrer"
          >
            <GitHubIcon fontSize="medium" />
          </a>
          <a href="mailto:linda.t.jansson@gmail.com">
            <MailIcon fontSize="medium" />
          </a>
          <button onClick={handleClick} id="hint-arrow" className="social">
            <WestIcon fontSize="medium" />
          </button>
        </footer>
      </section>
    </main>
  );
};

export default App;
