export const words = [
  "clock",
  "banana",
  "orange",
  "beer",
  "night",
  "bird",
  "dinosaur",
  "mountain",
  "rock",
  "duck",
  "cat",
  "apple",
  "robot",
  "ocean",
  "baseball",
  "bumblebee",
  "hangman",
  "bungalow",
  "factor",
  "auditor",
  "opinion",
  "definite",
  "vacuum",
  "pineapple",
  "kitten",
  "developer",
  "suitcase",
  "stadium",
  "infection",
  "altitude",
  "security",
  "discovery",
  "emigrate",
  "fever",
  "restaurant",
];
