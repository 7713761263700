import React from "react";
import "./Home.scss";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import Jokes from "./Jokes";
import lolcat from "../../images/lolcat.png";

const Home = ({ setActive }) => {
  const handleClickScroll = () => {
    const element = document.getElementById("main-footer");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    const arrow = document.getElementById("hint-arrow");
    if (arrow) arrow.classList.add("social-hint");
  };

  return (
    <main className="home">
      <header className="banner">
        <h1>Hello, my name is Linda</h1>
        <hr />
        <p>I'm a front end developer from Gothenburg.</p>
        <Button
          variant="outlined"
          endIcon={<EastIcon />}
          onClick={handleClickScroll}
        >
          get in touch
        </Button>
      </header>

      <div onClick={() => setActive("games")}>
        <h1>Wanna play some simple games?</h1>
        <hr />
        <aside>
          <p>
            Go ahead and try some. <br />
            Can't promise it will be fun though.
          </p>
        </aside>
      </div>
      <Jokes />
      <div>
        <h3>
          Some day you might be able to see your scores from the games you've
          played by clicking here.
        </h3>
      </div>
      <div>
        <p>
          Wanna take a break and stretch your shoulders or do a short easy
          workout? <i>...stay tuned.</i>
        </p>
      </div>
      <div>
        <p>
          Why Lolcat?
          <br />
          Cuz em funnies.
          <br />
          <br />
          <span className="small-text">(The image takes you to Google.)</span>
        </p>
        <a
          href="https://www.google.com/search?q=lolcat&oq=lolcat&gs_lcrp=EgZjaHJvbWUyCQgAEEUYORiABDIHCAEQABiABDIHCAIQABiABDIGCAMQRRg9MgYIBBBFGDwyBggFEEUYQTIGCAYQRRhBMgYIBxBFGEHSAQk4NzgwNmowajeoAgCwAgA&sourceid=chrome&ie=UTF-8&safe=active"
          title="lolcat"
        >
          <img src={lolcat} alt="LOLcat" />
        </a>
      </div>
    </main>
  );
};

export default Home;
