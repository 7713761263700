import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import "./Navigation.scss";

const MainMenu = ({ active, setActive }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anim, setAnim] = useState("menu");
  const [mainHeader, setMainHeader] = useState("main-header");

  const toggleMenu = (page) => {
    if (!menuOpen) {
      setAnim("menu open");
      setMenuOpen(!menuOpen);
    } else {
      setAnim("menu close");
      setTimeout(() => {
        setMenuOpen(!menuOpen);
        if (page) setActive(page);
      }, 500);
    }
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 25) {
      setMainHeader("main-header colored");
    } else {
      setMainHeader("main-header");
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      {!menuOpen ? (
        <header className={mainHeader}>
          <p onClick={() => setActive("home")} className="logo">
            lolcat
          </p>
          <button onClick={() => toggleMenu()}>
            <MenuIcon fontSize="large" className="menu-icon" />
          </button>
        </header>
      ) : (
        <nav className={anim}>
          <CloseIcon className="close-btn" onClick={() => toggleMenu()} />
          <ul>
            <li
              onClick={() => toggleMenu("home")}
              className={active === "home" ? "active" : undefined}
            >
              home
            </li>
            <li
              onClick={() => toggleMenu("games")}
              className={active === "games" ? "active" : undefined}
            >
              games
            </li>
            <li
              //onClick={() => toggleMenu("score")}
              className={active === "score" ? "active" : undefined}
            >
              score - coming soon
            </li>
            <li
              //onClick={() => toggleMenu("training")}
              className={active === "training" ? "active" : undefined}
            >
              stretch / training - coming soon
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default MainMenu;
