import React, { useEffect } from "react";
import "./Games.scss";
import { Button } from "@mui/material";

const InstallationPrompt = () => {
  useEffect(() => {
    console.log("showing prompt");
  }, []);

  let installPrompt = null;
  const installPage = document.getElementById("#install");

  window.addEventListener("beforeinstallprompt", (event) => {
    console.log("beforeinstallprompt");
    event.preventDefault();
    installPrompt = event;
    installPage.removeAttribute("hidden");
  });

  const handleInstallClick = async (answer) => {
    if (!installPrompt || !answer) {
      return;
    }
    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
    installPrompt = null;
    installPage.setAttribute("hidden", "");
  };

  return (
    <section id="installPage" hidden>
      <div className="overlay">
        <h1>You like playing?</h1>
        <h1>
          Install instantly, play again
          <br /> anytime.
        </h1>
        <Button
          id="install"
          onClick={() => handleInstallClick(true)}
          className="install-button"
        >
          Install
        </Button>
        <p onClick={() => handleInstallClick(false)}>Not now</p>
      </div>
    </section>
  );
};

export default InstallationPrompt;
