import React, { useState } from "react";
import "./Games.scss";
import Memory from "./memory/Memory";
import Hangman from "./hangman/Hangman";
import TicTacToe from "./tictactoe/TicTacToe";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import hang from "./assets/hangman.png";
import mem from "./assets/memory.png";
import ttt from "./assets/tictactoe.png";

const Games = ({ back }) => {
  const [game, setGame] = useState("");

  const goBack = () => {
    if (game === "") back();
    else setGame("");
  };

  return (
    <>
      <section className="games">
        <header className="games-header">
          <button onClick={() => goBack()}>
            <ChevronLeftIcon fontSize="large" className="chevron" />
          </button>
          <h1>{game === "" ? "games" : game}</h1>
          <ChevronLeftIcon fontSize="large" className="chevron" />
        </header>
        {game === "" && (
          <section className="grid-container">
            <div onClick={() => setGame("memory")}>
              <p>Memory</p>
              <img src={mem} alt="hangman" height={90} />
            </div>
            <div onClick={() => setGame("hangman")}>
              <p>Hangman</p>
              <img src={hang} alt="hangman" height={90} />
            </div>
            <div>
              <p>
                Four In A Row
                <br />
                <i>...stay tuned</i>
              </p>
            </div>
            <div onClick={() => setGame("tictactoe")}>
              <p>Tic Tac Toe</p>
              <img src={ttt} alt="tictactoe" height={70} />
            </div>
            <div></div>
            <div></div>
          </section>
        )}
        {game === "tictactoe" && <TicTacToe />}
        {game === "memory" && <Memory />}
        {game === "hangman" && <Hangman />}
      </section>
    </>
  );
};

export default Games;
